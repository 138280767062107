<script>
import { Bar } from 'vue-chartjs';

export default {
  
  name: "IncomeChart",
  
  extends: Bar,
  
  data() {
    return {

      chartdata: {
        labels: [
          '<$30K',
          '$30-40K',
          '$40-50K',
          '$50-60K',
          '$60-75K',
          '$75-100K',
          '$100-125K',
          '$125-150K',
          '$150-200K',
          '$200-250K',
          '$250-300K',
          '$300-500K',
          '>$500K'
        ],
        datasets: [{
          label: 'National Average',
          data: [],
          //backgroundColor: '#9C0000',
          borderColor: 'red',
          borderWidth: 1,
          type: 'line',
          pointRadius: 0
        },{
          label: 'Income',
          data: [],
          backgroundColor: '#8EA6F0',
          borderColor: '#475378',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        type: 'verticalBar',
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return `${value}%`;
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of Households'
            }
          }]
        },
        
        plugins: {
          labels: false
        }
      }
    }
  },

  props: {
    benchmarks: {
      type: Object,
      default: null
    },
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
  
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[1].data = [];
    
    this.chartdata.datasets[0].data.push(this.benchmarks.incomeLt30kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.incomeLt30kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income30to40kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income30to40kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income40to50kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income40to50kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income50to60kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income50to60kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income60to75kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income60to75kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income75to100kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income75to100kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income100to125kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income100to125kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income125to150kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income125to150kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income150to200kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income150to200kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income200to250kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income200to250kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income250to300kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income250to300kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.income300to500kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.income300to500kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.incomeGt500kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.incomeGt500kPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
