<script>
import { Bar } from 'vue-chartjs';

export default {
  
  name: "VehiclePropensityChart",
  
  extends: Bar,

  data() {
    return {

      chartdata: {
        labels: ['Compact', 'Truck', 'Domestic Sedan', 'Foreign Sedan', 'Luxury Sedan', 'SUV', 'Luxury SUV', 'Hybrid', 'Minivan', 'Sports Car', 'Compact SUV'],
        datasets: [{
          label: '',
          data: [],
          backgroundColor: '#9bb7d4',
          borderColor: '#4d5b6a',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: false
        },
        //type: 'horizontalBar',
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
               callback: (value) => {
                 return `${value}%`;
               }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of Households in Top 5 Deciles'
            }
          }]
        },
        
        plugins: {
          labels: false
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityCompactPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityTruckPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityDomesticSedanPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityForeignSedanPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityLuxurySedanPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensitySuvPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityLuxurySuvPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityHybridPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityMinivanPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensitySportsCarPct);
    this.chartdata.datasets[0].data.push(this.profiledata.vehiclePropensityCompactSuvPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
