<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {

  name: "SocialMatrixTableLeft",

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Category' },
        { key: 'hh', label: 'HHs'},
        { key: 'pct', label: '% of HHs' },
        { key: 'pm', label: '+/- Avg' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    benchmarks: {
      type: Object,
      default: null
    },
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("SocialMatrixTable this.profiledata=%o", this.profiledata);

    this.items = [
    {
      val: 'Timecards',
      hh: this.profiledata.segTimecardsHh,
      pct: this.profiledata.segTimecardsPct.toFixed(1) + "%",
      pm: (this.profiledata.segTimecardsPct - this.benchmarks.segTimecardsBm).toFixed(1) + "%",
      ppl: this.profiledata.segTimecardsPpl
    },{      
      val: 'Learning',
      hh: this.profiledata.segLearnRopesHh,
      pct: this.profiledata.segLearnRopesPct.toFixed(1) + "%",
      pm: (this.profiledata.segLearnRopesPct - this.benchmarks.segLearnRopesBm).toFixed(1) + "%",
      ppl: this.profiledata.segLearnRopesPpl
    },{      
      val: 'Urban Yuppies',
      hh: this.profiledata.segUrbYuppieHh,
      pct: this.profiledata.segUrbYuppiePct.toFixed(1) + "%",
      pm: (this.profiledata.segUrbYuppiePct - this.benchmarks.segUrbYuppieBm).toFixed(1) + "%",
      ppl: this.profiledata.segUrbYuppiePpl
    },{      
      val: 'Career & Family',
      hh: this.profiledata.segCareerFamilyHh,
      pct: this.profiledata.segCareerFamilyPct.toFixed(1) + "%",
      pm: (this.profiledata.segCareerFamilyPct - this.benchmarks.segCareerFamilyBm).toFixed(1) + "%",
      ppl: this.profiledata.segCareerFamilyPpl
    },{      
      val: 'Paycheck Planners',
      hh: this.profiledata.segPaychkPlannerHh,
      pct: this.profiledata.segPaychkPlannerPct.toFixed(1) + "%",
      pm: (this.profiledata.segPaychkPlannerPct - this.benchmarks.segPaychkPlannerBm).toFixed(1) + "%",
      ppl: this.profiledata.segPaychkPlannerPpl
    },{      
      val: 'Following Orders',
      hh: this.profiledata.segFollowOrdersHh,
      pct: this.profiledata.segFollowOrdersPct.toFixed(1) + "%",
      pm: (this.profiledata.segFollowOrdersPct - this.benchmarks.segFollowOrdersBm).toFixed(1) + "%",
      ppl: this.profiledata.segFollowOrdersPpl
    },{      
      val: 'Asset Builders',
      hh: this.profiledata.segAssetBuilderHh,
      pct: this.profiledata.segAssetBuilderPct.toFixed(1) + "%",
      pm: (this.profiledata.segAssetBuilderPct - this.benchmarks.segAssetBuilderBm).toFixed(1) + "%",
      ppl: this.profiledata.segAssetBuilderPpl
    },{      
      val: 'Independent Women',
      hh: this.profiledata.segIndWomenHh,
      pct: this.profiledata.segIndWomenPct.toFixed(1) + "%",
      pm: (this.profiledata.segIndWomenPct - this.benchmarks.segIndWomenBm).toFixed(1) + "%",
      ppl: this.profiledata.segIndWomenPpl
    },{      
      val: 'Independent Singles',
      hh: this.profiledata.segIndSingleHh,
      pct: this.profiledata.segIndSinglePct.toFixed(1) + "%",
      pm: (this.profiledata.segIndSinglePct - this.benchmarks.segIndSingleBm).toFixed(1) + "%",
      ppl: this.profiledata.segIndSinglePpl
    },{      
      val: 'Core Family',
      hh: this.profiledata.segCoreFamilyHh,
      pct: this.profiledata.segCoreFamilyPct.toFixed(1) + "%",
      pm: (this.profiledata.segCoreFamilyPct - this.benchmarks.segCoreFamilyBm).toFixed(1) + "%",
      ppl: this.profiledata.segCoreFamilyPpl
    },{      
      val: 'Family Planners',
      hh: this.profiledata.segFamPlannerHh,
      pct: this.profiledata.segFamPlannerPct.toFixed(1) + "%",
      pm: (this.profiledata.segFamPlannerPct - this.benchmarks.segFamPlannerBm).toFixed(1) + "%",
      ppl: this.profiledata.segFamPlannerPpl
    },{      
      val: 'Daily Challenges',
      hh: this.profiledata.segDayChallengesHh,
      pct: this.profiledata.segDayChallengesPct.toFixed(1) + "%",
      pm: (this.profiledata.segDayChallengesPct - this.benchmarks.segDayChallengesBm).toFixed(1) + "%",
      ppl: this.profiledata.segDayChallengesPpl
    }];
  }
}
</script>
