<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */

export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Age Range' },
        { key: 'pct', label: 'Percentage' },
        { key: 'pm', label: '+/- Avg' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    },
    benchmarks: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
   // debugger;

    this.items = [
    {
      val: '18-22 Years',
      pct: this.profiledata.age18to22Pct.toFixed(1) + "%",
      pm: (this.profiledata.age18to22Pct - this.benchmarks.age18to22Bm).toFixed(1) + "%",
      ppl: this.profiledata.age18to22Ppl,
    },{
      val: '23-29 Years',
      pct: this.profiledata.age23to29Pct.toFixed(1) + "%",
      pm: (this.profiledata.age23to29Pct - this.benchmarks.age23to29Bm).toFixed(1) + "%",
      ppl: this.profiledata.age23to29Ppl,
    },{
      val: '30-39 Years',
      pct: this.profiledata.age30to39Pct.toFixed(1) + "%",
      pm: (this.profiledata.age30to39Pct - this.benchmarks.age30to39Bm).toFixed(1) + "%",
      ppl: this.profiledata.age30to39Ppl,
    },{
      val: '40-49 Years',
      pct: this.profiledata.age40to49Pct.toFixed(1) + "%",
      pm: (this.profiledata.age40to49Pct - this.benchmarks.age40to49Bm).toFixed(1) + "%",
      ppl: this.profiledata.age40to49Ppl,
    },{
      val: '50-59 Years',
      pct: this.profiledata.age50to59Pct.toFixed(1) + "%",
      pm: (this.profiledata.age50to59Pct - this.benchmarks.age50to59Bm).toFixed(1) + "%",
      ppl: this.profiledata.age50to59Ppl,
    },{
      val: '60-69 Years',
      pct: this.profiledata.age60to69Pct.toFixed(1) + "%",
      pm: (this.profiledata.age60to69Pct - this.benchmarks.age60to69Bm).toFixed(1) + "%",
      ppl: this.profiledata.age60to69Ppl,
    },{
      val: '70-79 Years',
      pct: this.profiledata.age70to79Pct.toFixed(1) + "%",
      pm: (this.profiledata.age70to79Pct - this.benchmarks.age70to79Bm).toFixed(1) + "%",
      ppl: this.profiledata.age70to79Ppl,
    },{
      val: '80-89 Years',
      pct: this.profiledata.age80to89Pct.toFixed(1) + "%",
      pm: (this.profiledata.age80to89Pct - this.benchmarks.age80to89Bm).toFixed(1) + "%",
      ppl: this.profiledata.age80to89Ppl,
    },{
      val: '90+ Years',
      pct: this.profiledata.age90PlusPct.toFixed(1) + "%",
      pm: (this.profiledata.age90PlusPct - this.benchmarks.age90PlusBm).toFixed(1) + "%",
      ppl: this.profiledata.age90PlusPpl,
    }];
  }
}
</script>
