<script>
import { Radar } from 'vue-chartjs';
//import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  /* eslint-disable no-console */

  name: "SocialMatrixChart",
  
  extends: Radar,

  // components: {
  //   LabelsPlugin
  // },
  
  data() {
    return {

      chartdata: {
        labels: ['Timecards',
                 'Learning',
                 'Urban Yuppies',
                 'Career & Family',
                 'Paycheck Planners',
                 'Following Orders',
                 'Asset Builders',
                 'Independent Women',
                 'Independent Singles',
                 'Core Families',
                 'Family Planners',
                 'Daily Challenges',
                 'Luxury Lifestyles',
                 'Comfortable Ind.',
                 'On Approach',
                 'Tax Free Savers',
                 'Big Box Greeters',
                 'Solid & Slow',
                 'Public Links',
                 'Pace & Protection',
                 'Snow Birds',
                 'Early Bird Specials',
                 'Lunch Bunch',
                 'Gardening'
                 ],
        datasets: [{
          label: 'Social Matrix Category',
          data: [],
					backgroundColor: '#c0c7e3',
          borderColor: '#999fb5',
          pointRadius: 0,
          borderWidth: 1
        },{
          label: 'National Average',
          data: [],
          backgroundColor: '#f8bfbf',
          borderColor: '#ee6767',
          pointRadius: 0,
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        }
      }
    }
  },

  props: {
    benchmarks: {
      type: Object,
      default: null
    },
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    //console.info("SocialMatrixTable this.profiledata=%o", this.profiledata);
    //console.info("SocialMatrixTable this.benchmarks=%o", this.benchmarks);

    this.chartdata.datasets[1].data = [];
    this.chartdata.datasets[0].data = [];

    this.chartdata.datasets[1].data.push(this.benchmarks.segTimecardsBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segTimecardsPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segLearnRopesBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segLearnRopesPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segUrbYuppieBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segUrbYuppiePct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segCareerFamilyBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segCareerFamilyPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segPaychkPlannerBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segPaychkPlannerPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segFollowOrdersBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segFollowOrdersPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segAssetBuilderBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segAssetBuilderPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segIndWomenBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segIndWomenPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segIndSingleBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segIndSinglePct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segCoreFamilyBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segCoreFamilyPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segFamPlannerBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segFamPlannerPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segDayChallengesBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segDayChallengesPct);

    this.chartdata.datasets[1].data.push(this.benchmarks.segLuxLifeBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segLuxLifePct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segComfIndepBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segComfIndepPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segOnApproachBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segOnApproachPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segTaxFreeSaveBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segTaxFreeSavePct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segBigBoxBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segBigBoxPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segSolidSlowBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segSolidSlowPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segPublicLinksBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segPublicLinksPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segPaceProtectBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segPaceProtectPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segSnowBirdsBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segSnowBirdsPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segEarlyBirdBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segEarlyBirdPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segLunchBunchBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segLunchBunchPct);
    
    this.chartdata.datasets[1].data.push(this.benchmarks.segGardeningBm);
    this.chartdata.datasets[0].data.push(this.profiledata.segGardeningPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
