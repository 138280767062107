<script>
import { Pie } from 'vue-chartjs';
import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  
  name: "GenderChart",

  components: {
    LabelsPlugin
  },
  
  extends: Pie,
  
  data() {
    return {

      chartdata: {
        labels: ['Adult Male', 'Adult Female', 'Both'],
        datasets: [{
          label: 'Household Gender',
          data: [],
					backgroundColor: [
            '#0080ff',
            '#fe66f9',
            '#4ca64c'
					],
          borderColor: [
            // '#004c99',
            // '#983d95',
            // '#2d632d'
           '#ffffff',
           '#ffffff',
           '#ffffff'
          ],
          borderWidth: 10
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: ['white', 'white', 'white'],
            precision: 1
          }
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.genderMalePct);
    this.chartdata.datasets[0].data.push(this.profiledata.genderFemalePct);
    this.chartdata.datasets[0].data.push(this.profiledata.genderBothPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
