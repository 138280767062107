<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {

  name: "IncomeTable",

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Income Range' },
        { key: 'pct', label: 'Percentage' },
        { key: 'pm', label: '+/- Avg' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    },
    benchmarks: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("IncomeTable this.profiledata=%o", this.profiledata);

    this.items = [
    {
      val: '<$30K',
      pct: this.profiledata.incomeLt30kPct.toFixed(1) + "%",
      pm: (this.profiledata.incomeLt30kPct - this.benchmarks.incomeLt30kBm).toFixed(1) + "%",
      ppl: this.profiledata.incomeLt30kPpl,
    },{
      val: '$30K-$40K',
      pct: this.profiledata.income30to40kPct.toFixed(1) + "%",
      pm: (this.profiledata.income30to40kPct - this.benchmarks.income30to40kBm).toFixed(1) + "%",
      ppl: this.profiledata.income30to40kPpl,
    },{
      val: '$40K-$50K',
      pct: this.profiledata.income40to50kPct.toFixed(1) + "%",
      pm: (this.profiledata.income40to50kPct - this.benchmarks.income40to50kBm).toFixed(1) + "%",
      ppl: this.profiledata.income40to50kPpl,
    },{
      val: '$50K-$60K',
      pct: this.profiledata.income50to60kPct.toFixed(1) + "%",
      pm: (this.profiledata.income50to60kPct - this.benchmarks.income50to60kBm).toFixed(1) + "%",
      ppl: this.profiledata.income50to60kPpl,
    },{
      val: '$60K-$75K',
      pct: this.profiledata.income60to75kPct.toFixed(1) + "%",
      pm: (this.profiledata.income60to75kPct - this.benchmarks.income60to75kBm).toFixed(1) + "%",
      ppl: this.profiledata.income60to75kPpl,
    },{
      val: '$75K-$100K',
      pct: this.profiledata.income75to100kPct.toFixed(1) + "%",
      pm: (this.profiledata.income75to100kPct - this.benchmarks.income75to100kBm).toFixed(1) + "%",
      ppl: this.profiledata.income75to100kPpl,
    },{
      val: '$100K-$125K',
      pct: this.profiledata.income100to125kPct.toFixed(1) + "%",
      pm: (this.profiledata.income100to125kPct - this.benchmarks.income100to125kBm).toFixed(1) + "%",
      ppl: this.profiledata.income100to125kPpl,
    },{
      val: '$125K-$150K',
      pct: this.profiledata.income125to150kPct.toFixed(1) + "%",
      pm: (this.profiledata.income125to150kPct - this.benchmarks.income125to150kBm).toFixed(1) + "%",
      ppl: this.profiledata.income125to150kPpl,
    },{
      val: '$150K-$200K',
      pct: this.profiledata.income150to200kPct.toFixed(1) + "%",
      pm: (this.profiledata.income150to200kPct - this.benchmarks.income150to200kBm).toFixed(1) + "%",
      ppl: this.profiledata.income150to200kPpl,
    },{
      val: '$200K-$250K',
      pct: this.profiledata.income200to250kPct.toFixed(1) + "%",
      pm: (this.profiledata.income200to250kPct - this.benchmarks.income200to250kBm).toFixed(1) + "%",
      ppl: this.profiledata.income200to250kPpl,
    },{
      val: '$250K-$300K',
      pct: this.profiledata.income250to300kPct.toFixed(1) + "%",
      pm: (this.profiledata.income250to300kPct - this.benchmarks.income250to300kBm).toFixed(1) + "%",
      ppl: this.profiledata.income250to300kPpl,
    },{
      val: '$300K-$500K',
      pct: this.profiledata.income300to500kPct.toFixed(1) + "%",
      pm: (this.profiledata.income300to500kPct - this.benchmarks.income300to500kBm).toFixed(1) + "%",
      ppl: this.profiledata.income300to500kPpl,
    },{
      val: '>$500K',
      pct: this.profiledata.incomeGt500kPct.toFixed(1) + "%",
      pm: (this.profiledata.incomeGt500kPct - this.benchmarks.incomeGt500kBm).toFixed(1) + "%",
      ppl: this.profiledata.incomeGt500kPpl,
    }];
  }
}
</script>
