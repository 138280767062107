<script>
import { Pie } from 'vue-chartjs';
import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  
  name: "PresenceOfChildrenChart",

  components: {
    LabelsPlugin
  },
  
  extends: Pie,
  
  data() {
    return {

      chartdata: {
        labels: ['Yes', 'No'],
        datasets: [{
          label: 'Presence of Children',
          data: [],
					backgroundColor: [
            '#bd5549',
            '#e3a787'
					],
          borderColor: [
            '#5e2a24',
            '#715343'
          ],
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: ['white', 'white'],
            precision: 1
          }
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.presenceOfChildrenYesPct);
    this.chartdata.datasets[0].data.push(this.profiledata.presenceOfChildrenNoPct)

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
