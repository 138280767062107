<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
export default {

  name: 'EthnicityTable',

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Ethnicity' },
        { key: 'pct', label: 'Percentage' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    this.items = [
      {
        val: 'White',
        pct: this.profiledata.ethnicWhitePct.toFixed(1) + "%",
        ppl: this.profiledata.ethnicWhitePpl
      },{
        val: 'Black',
        pct: this.profiledata.ethnicBlackPct.toFixed(1) + "%",
        ppl: this.profiledata.ethnicBlackPpl
      },{
        val: 'Hispanic',
        pct: this.profiledata.ethnicHispanicPct.toFixed(1) + "%",
        ppl: this.profiledata.ethnicHispanicPpl
      },{
        val: 'Asian',
        pct: this.profiledata.ethnicAsianPct.toFixed(1) + "%",
        ppl: this.profiledata.ethnicAsianPpl
      }
    ];
  }
}
</script>
