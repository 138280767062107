<script>
import { PolarArea } from 'vue-chartjs';
import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  
  name: "PoliticalPartyChart",
  
  extends: PolarArea,

  components: {
    LabelsPlugin
  },
  
  data() {
    return {

      chartdata: {
        labels: ['Democrat', 'Republican', 'Unaffiliated'], //, 'Libertarian', 'Green', 'Unidentified', 'Other'],
        datasets: [{
          label: 'Political Party',
          data: [],
					backgroundColor: [
            '#5182ba',
            '#fa8072',
            '#bdeba7',
            // '#ffaa99',
            // '#005959',
            // '#ff7518',
            // '#6b5435'
					],
          borderColor: [
            '#28415d',
            '#7d4039',
            '#5e7553',
            // '#99665b',
            // '#002c2c',
            // '#7f3a0c',
            // '#352a1a'
          ],
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: ['white', 'white', 'green'],
            precision: 1
          }
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdDemocratPct);
    this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdRepublicanPct)
    this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdUnaffiliatedPct);
    // this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdLibertarianPct);
    // this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdGreenPct);
    // this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdUnidentifiedPct);
    // this.chartdata.datasets[0].data.push(this.profiledata.regPartyCdOtherPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
