<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */

import { Bar } from 'vue-chartjs';

export default {
  
  name: "AgeChart",
  
  extends: Bar,

  data() {
    return {

      chartdata: {
        labels: ['18-22', '23-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90+'],
        datasets: [{
          label: 'National Average',
          data: [],
          backgroundColor: '#9C0000',
          borderColor: '#4e0000',
          borderWidth: 1
        },{
          label: 'Age Range',
          data: [],
          backgroundColor: '#8EA6F0',
          borderColor: '#475378',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
               callback: (value) => {
                 return `${value}%`;
               }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of People'
            }
          }]
        },
        
        plugins: {
          labels: false
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    },
    benchmarks: {
      type: Object,
      default: null
    }
  },

  mounted () {

    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[1].data = [];

    this.chartdata.datasets[0].data.push(this.benchmarks.age18to22Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age18to22Pct);

    this.chartdata.datasets[0].data.push(this.benchmarks.age23to29Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age23to29Pct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.age30to39Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age30to39Pct);

    this.chartdata.datasets[0].data.push(this.benchmarks.age40to49Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age40to49Pct);

    this.chartdata.datasets[0].data.push(this.benchmarks.age50to59Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age50to59Pct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.age60to69Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age60to69Pct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.age70to79Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age70to79Pct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.age80to89Bm);
    this.chartdata.datasets[1].data.push(this.profiledata.age80to89Pct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.age90PlusBm);
    this.chartdata.datasets[1].data.push(this.profiledata.age90PlusPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
