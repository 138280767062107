<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Vehicle Propensity' },
        { key: 'pct', label: 'Top 5 Deciles' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("AgeTable this=%o", this);

    this.items = [
    {
      val: 'Compact',
      pct: this.profiledata.vehiclePropensityCompactPct.toFixed(1) + "%",
    },{
      val: 'Truck',
      pct: this.profiledata.vehiclePropensityTruckPct.toFixed(1) + "%",
    },{
      val: 'Domestic Sedan',
      pct: this.profiledata.vehiclePropensityDomesticSedanPct.toFixed(1) + "%",
    },{
      val: 'Foreign Sedan',
      pct: this.profiledata.vehiclePropensityForeignSedanPct.toFixed(1) + "%",
    },{
      val: 'Luxury Sedan',
      pct: this.profiledata.vehiclePropensityLuxurySedanPct.toFixed(1) + "%",
    },{
      val: 'SUV',
      pct: this.profiledata.vehiclePropensitySuvPct.toFixed(1) + "%",
    },{
      val: 'Luxury SUV',
      pct: this.profiledata.vehiclePropensityLuxurySuvPct.toFixed(1) + "%",
    },{
      val: 'Hybrid',
      pct: this.profiledata.vehiclePropensityHybridPct.toFixed(1) + "%",
    },{
      val: 'Minivan',
      pct: this.profiledata.vehiclePropensityMinivanPct.toFixed(1) + "%",
    },{
      val: 'Sports Car',
      pct: this.profiledata.vehiclePropensitySportsCarPct.toFixed(1) + "%",
    },{
      val: 'Compact SUV',
      pct: this.profiledata.vehiclePropensityCompactSuvPct.toFixed(1) + "%",
    }];
  }
}
</script>
