<template>
  <div class="profileview">
    <h1 style="margin-top:20px">Profile of Zip Code {{ pc.zip5 }}</h1>
    <div class="mw-1200 mx-auto">

      <b-card>
        <div class="header">
          Summary
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table">
              <SummaryTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Social Matrix
        </div>
        <div class="row">
          <div class="col-sm-2">
          </div>
          <div class="col-sm-8">
            <div>
              <SocialMatrixChart v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-2">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <SocialMatrixTableLeft v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="table">
              <SocialMatrixTableRight v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Age Range
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <AgeTable v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="chart">
              <AgeChart v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Length of Residence
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <YearsResidentTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="chart">
              <YearsResidentChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Political Party
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <PoliticalPartyTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
          <div class="col-sm-4">
            <div class="chart">
              <PoliticalPartyChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Income
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <IncomeTable v-if="loaded" :profiledata="profiledata" :benchmarks="benchmarks"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="chart">
              <IncomeChart v-if="loaded" :profiledata="profiledata" :benchmarks="benchmarks"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Discretionary Income
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <DiscIncomeTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
          <div class="col-sm-3">
            <div class="chart">
              <DiscIncomeChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-2">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Presence of Children
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <PresenceOfChildrenTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
          <div class="col-sm-3">
            <div class="chart">
              <PresenceOfChildrenChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-2">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Home Value
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <HomeValueTable v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="chart">
              <HomeValueChart v-if="loaded" :benchmarks="benchmarks" :profiledata="profiledata"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Auto Consideration Set
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <VehiclePropensityTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="chart">
              <VehiclePropensityChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Household Gender
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <GenderTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-1">
          </div>
          <div class="col-sm-3">
            <div class="chart">
              <GenderChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-2">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Ethnicity
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <EthnicityTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-1">
          </div>
          <div class="col-sm-4">
            <div class="chart">
              <EthnicityChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-1">
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="header">
          Property Type
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="table">
              <PropertyTypeTable v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
          <div class="col-sm-4">
            <div class="chart">
              <PropertyTypeChart v-if="loaded" :profiledata="profiledata"/>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
        </div>
      </b-card>

      <br><br><br><br>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */

import { pcMixin } from '@/mixins/pcMixin';
import { apiMixin } from '@/mixins/apiMixin.js';
import SummaryTable from '@/components/SummaryTable';
import SocialMatrixChart from '@/components/SocialMatrixChart';
import SocialMatrixTableLeft from '@/components/SocialMatrixTableLeft';
import SocialMatrixTableRight from '@/components/SocialMatrixTableRight';
import AgeTable from '@/components/AgeTable.vue';
import AgeChart from '@/components/AgeChart.vue';
import YearsResidentTable from '@/components/YearsResidentTable.vue';
import YearsResidentChart from '@/components/YearsResidentChart.vue';
import PoliticalPartyTable from '@/components/PoliticalPartyTable.vue';
import PoliticalPartyChart from '@/components/PoliticalPartyChart.vue';
import IncomeTable from '@/components/IncomeTable.vue';
import IncomeChart from '@/components/IncomeChart.vue';
import PresenceOfChildrenTable from '@/components/PresenceOfChildrenTable.vue';
import PresenceOfChildrenChart from '@/components/PresenceOfChildrenChart.vue';
import HomeValueTable from '@/components/HomeValueTable.vue';
import HomeValueChart from '@/components/HomeValueChart.vue';
import VehiclePropensityTable from '@/components/VehiclePropensityTable.vue';
import VehiclePropensityChart from '@/components/VehiclePropensityChart.vue';
import DiscIncomeTable from '@/components/DiscIncomeTable.vue';
import DiscIncomeChart from '@/components/DiscIncomeChart.vue';
import GenderTable from '@/components/GenderTable.vue';
import GenderChart from '@/components/GenderChart.vue';
import EthnicityTable from '@/components/EthnicityTable.vue';
import EthnicityChart from '@/components/EthnicityChart.vue';
import PropertyTypeTable from '@/components/PropertyTypeTable.vue';
import PropertyTypeChart from '@/components/PropertyTypeChart.vue';

export default {
  name: "ProfileView",

  mixins: [ pcMixin, apiMixin ],

  components: {
    SummaryTable,
    SocialMatrixChart,
    SocialMatrixTableLeft,
    SocialMatrixTableRight,
    AgeTable,
    AgeChart,
    YearsResidentTable,
    YearsResidentChart,
    PoliticalPartyTable,
    PoliticalPartyChart,
    IncomeTable,
    IncomeChart,
    PresenceOfChildrenTable,
    PresenceOfChildrenChart,
    HomeValueTable,
    HomeValueChart,
    VehiclePropensityTable,
    VehiclePropensityChart,
    DiscIncomeTable,
    DiscIncomeChart,
    GenderTable,
    GenderChart,
    EthnicityTable,
    EthnicityChart,
    PropertyTypeTable,
    PropertyTypeChart
  },

  data() {
    return {
      sem: this.$store.getters.sem,
      pc: this.$store.getters.pc,

      loaded: false,
      profiledata: {},
      benchmarks: {}
    }
  },

  async mounted() {
    this.pcSpinnerShow();
    this.loaded = false;
    this.apiGetProfileData(this.pc.zip5)
    .then((response) => {
      this.profiledata = response.data;
      this.apiGetBenchmarks()
      .then((response) => {
        this.benchmarks = response.data;
      }).catch((response) => { 
        console.log("apiGetBenchmarks failed response=%o", response);
      })
      .finally(() => {
        this.loaded = true;
        this.pcSpinnerHide();
      })
    }).catch((response) => {
      console.log("apiGetProfileData failed response=%o", response);
    })
  }
}
</script>

<!-- Note: Can't use scoped here. -->
<style>
  .chart {
    margin: auto;
  }
  .table {
    margin: auto;
  }
  .header {
    font-weight: bold;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;
    background-color: #01A7C3;
    margin-bottom: 20px;
  }
  .table_header {
    background-color: darkgrey;
  }
  .hidden_header {
    display:none;
  }

</style>