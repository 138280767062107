<script>
import { Doughnut } from 'vue-chartjs';
import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  
  name: "PropertyTypeChart",
  
  extends: Doughnut,

  components: {
    LabelsPlugin
  },
  
  data() {
    return {

      chartdata: {
        labels: [
          'Apartment', 
          'Condo', 
          'Multi-Family', 
          'PO Box', 
          'Rural', 
          'Single Family', 
          'Trailer'],
        datasets: [{
          label: 'Property Type',
          data: [],
					backgroundColor: [
            '#004ca3',
            '#8a51a5',
            '#cb5e99',
            '#f47b89',
            '#ffa47e',
            '#ffd286',
            '#7fbf7f'
					],
          borderColor: 'white',
          borderWidth: 5
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: '#0f0f0f',
            // [
            //   'white',
            //   'white', 
            //   'white', 
            //   'white',
            //   'black',
            //   'black',
            //   'black'
            // ],
            precision: 1
          }
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeApartmentPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeCondoPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeMultiFamilyPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypePoBoxPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeRuralPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeSingleFamilyPct);
    this.chartdata.datasets[0].data.push(this.profiledata.propertyTypeTrailerPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
