<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {

  name: 'DiscIncomeTable',
  
  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Est. Disc. Income' },
        { key: 'hh', label: 'Households' },
        { key: 'pct', label: 'Percentage' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("DiscIncomeTable this.profiledata=%o", this.profiledata);

    this.items = [
      {
        val: 'High',
        hh: this.profiledata.discIncomeHighHh,
        pct: this.profiledata.discIncomeHighPct.toFixed(1) + "%",
        ppl: this.profiledata.discIncomeHighPpl
      },{
        val: 'Above Average',
        hh: this.profiledata.discIncomeAboveAverageHh,
        pct: this.profiledata.discIncomeAboveAveragePct.toFixed(1) + "%",
        ppl: this.profiledata.discIncomeAboveAveragePpl
      },{
        val: 'Average',
        hh: this.profiledata.discIncomeAverageHh,
        pct: this.profiledata.discIncomeAveragePct.toFixed(1) + "%",
        ppl: this.profiledata.discIncomeAveragePpl
      },{
        val: 'Minimal',
        hh: this.profiledata.discIncomeMinimalHh,
        pct: this.profiledata.discIncomeMinimalPct.toFixed(1) + "%",
        ppl: this.profiledata.discIncomeMinimalPpl
      }
    ];
  }
}
</script>
