<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="hidden_header" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fields: [ 'label', 'value' ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    this.items = [
      {
        label: 'Zip Code',
        value: this.profiledata.zip5
      },{
        label: 'Total Households in Zip Code',
        value: this.profiledata.householdTotal
      },{
        label: 'Total People in Zip Code',
        value: this.profiledata.personCtTotal
      },{
        label: 'Average Number of People per Household',
        value: this.profiledata.personCtAvg.toFixed(1)
      },{
        label: 'Average Years Resident per Household',
        value: this.profiledata.yearsResidentAvg.toFixed(1)
      },{
        label: 'Average Age of People in Zip Code',
        value: this.profiledata.ageAvg.toFixed(1)
      }
    ];
  }
}
</script>
