<script>
/* eslint-disable no-console */

import { Bar } from 'vue-chartjs';

export default {
  
  name: "HomeValueChart",
  
  extends: Bar,
  
  data() {
    return {

      chartdata: {
        labels: [
          '$1-25K',
          '$25-50K',
          '$50-75K',
          '$75-100K',
          '$100-125K',
          '$125-150K',
          '$150-175K',
          '$175-200K',
          '$200-225K',
          '$225-250K',
          '$250-275K',
          '$275-300K',
          '$300-350K',
          '$350-400K',
          '$400-450K',
          '$450-500K',
          '$500-775K',
          '$775K-1M',
          '$1-2M',
          '$2-3M',
          '$3-5M',
          '$5-10M',
          '>$10M'
        ],
        datasets: [{
          label: 'National Average',
          data: [],
          //backgroundColor: '#9C0000',
          borderColor: 'red',
          borderWidth: 1,
          type: 'line',
          pointRadius: 0
        },{
          label: 'Home Value',
          data: [],
          backgroundColor: '#A47BA4',
          borderColor: '#523d52',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        
        legend: {
          display: true
        },
        
        type: 'horizontalBar',

        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
               callback: (value) => {
                 return `${value}%`;
               }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of Households'
            }
          }]
        },
        
        plugins: {
          labels: false
        }
      }
    }
  },

  props: {
    benchmarks: {
      type: Object,
      default: null
    },
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    //console.info("HomeValueChart profiledata=%o", this.profiledata);
    //console.info("SocialMatrixChart benchmarks=%o", this.benchmarks);

    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[1].data = [];

    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue1to25kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue1to25kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue25to50kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue25to50kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue50to75kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue50to75kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue75to100kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue75to100kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue100to125kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue100to125kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue125to150kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue125to150kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue150to175kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue150to175kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue175to200kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue175to200kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue200to225kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue200to225kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue225to250kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue225to250kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue250to275kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue250to275kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue275to300kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue275to300kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue300to350kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue300to350kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue350to400kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue350to400kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue400to450kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue400to450kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue450to500kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue450to500kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue500to775kBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue500to775kPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue775kto1mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue775kto1mPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue1to2mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue1to2mPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue2to3mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue2to3mPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue3to5mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue3to5mPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValue5to10mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValue5to10mPct);
    
    this.chartdata.datasets[0].data.push(this.benchmarks.homeValueGt10mBm);
    this.chartdata.datasets[1].data.push(this.profiledata.homeValueGt10mPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
