<script>
import { Doughnut } from 'vue-chartjs';
import LabelsPlugin from 'chartjs-plugin-labels';

export default {
  
  name: "DiscIncomeChart",
  
  extends: Doughnut,

  components: {
    LabelsPlugin
  },
  
  data() {
    return {

      chartdata: {
        labels: ['High', 'Above Avg.', 'Avg.', 'Minimal'],
        datasets: [{
          label: 'Discretionary Income',
          data: [],
					backgroundColor: [
            '#458b00',
            '#99b898',
            '#eeba30',
            '#ae0001'
					],
          borderColor: '#133512',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: true
        },
        plugins: {
          labels: {
            // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
            render: 'percentage',
            precision: 1,
            fontColor: [
              'white',
              'black',
              'black',
              'white'
            ]
          }
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.discIncomeHighPct);
    this.chartdata.datasets[0].data.push(this.profiledata.discIncomeAboveAveragePct)
    this.chartdata.datasets[0].data.push(this.profiledata.discIncomeAveragePct);
    this.chartdata.datasets[0].data.push(this.profiledata.discIncomeMinimalPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
