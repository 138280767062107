<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {

  name: "SocialMatrixTableRight",

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Category' },
        { key: 'hh', label: 'HHs'},
        { key: 'pct', label: '% of HHs' },
        { key: 'pm', label: '+/- Avg' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    benchmarks: {
      type: Object,
      default: null
    },
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("SocialMatrixTable this.profiledata=%o", this.profiledata);

    this.items = [
    {      
      val: 'Luxury Lifestyles',
      hh: this.profiledata.segLuxLifeHh,
      pct: this.profiledata.segLuxLifePct.toFixed(1) + "%",
      pm: (this.profiledata.segLuxLifePct - this.benchmarks.segLuxLifeBm).toFixed(1) + "%",
      ppl: this.profiledata.segLuxLifePpl
    },{      
      val: 'Comfortable Indep.',
      hh: this.profiledata.segComfIndepHh,
      pct: this.profiledata.segComfIndepPct.toFixed(1) + "%",
      pm: (this.profiledata.segComfIndepPct - this.benchmarks.segComfIndepBm).toFixed(1) + "%",
      ppl: this.profiledata.segComfIndepPpl
    },{      
      val: 'On Approach',
      hh: this.profiledata.segOnApproachHh,
      pct: this.profiledata.segOnApproachPct.toFixed(1) + "%",
      pm: (this.profiledata.segOnApproachPct - this.benchmarks.segOnApproachBm).toFixed(1) + "%",
      ppl: this.profiledata.segOnApproachPpl
    },{      
      val: 'Tax Free Savers',
      hh: this.profiledata.segTaxFreeSaveHh,
      pct: this.profiledata.segTaxFreeSavePct.toFixed(1) + "%",
      pm: (this.profiledata.segTaxFreeSavePct - this.benchmarks.segTaxFreeSaveBm).toFixed(1) + "%",
      ppl: this.profiledata.segTaxFreeSavePpl
    },{      
      val: 'Big Box Greeters',
      hh: this.profiledata.segBigBoxHh,
      pct: this.profiledata.segBigBoxPct.toFixed(1) + "%",
      pm: (this.profiledata.segBigBoxPct - this.benchmarks.segBigBoxBm).toFixed(1) + "%",
      ppl: this.profiledata.segBigBoxPpl
    },{      
      val: 'Solid & Slow',
      hh: this.profiledata.segSolidSlowHh,
      pct: this.profiledata.segSolidSlowPct.toFixed(1) + "%",
      pm: (this.profiledata.segSolidSlowPct - this.benchmarks.segSolidSlowBm).toFixed(1) + "%",
      ppl: this.profiledata.segSolidSlowPpl
    },{      
      val: 'Public Links',
      hh: this.profiledata.segPublicLinksHh,
      pct: this.profiledata.segPublicLinksPct.toFixed(1) + "%",
      pm: (this.profiledata.segPublicLinksPct - this.benchmarks.segPublicLinksBm).toFixed(1) + "%",
      ppl: this.profiledata.segPublicLinksPpl
    },{      
      val: 'Pace & Protection',
      hh: this.profiledata.segPaceProtectHh,
      pct: this.profiledata.segPaceProtectPct.toFixed(1) + "%",
      pm: (this.profiledata.segPaceProtectPct - this.benchmarks.segPaceProtectBm).toFixed(1) + "%",
      ppl: this.profiledata.segPaceProtectPpl
    },{      
      val: 'Snow Birds',
      hh: this.profiledata.segSnowBirdsHh,
      pct: this.profiledata.segSnowBirdsPct.toFixed(1) + "%",
      pm: (this.profiledata.segSnowBirdsPct - this.benchmarks.segSnowBirdsBm).toFixed(1) + "%",
      ppl: this.profiledata.segSnowBirdsPpl
    },{      
      val: 'Early Bird Specials',
      hh: this.profiledata.segEarlyBirdHh,
      pct: this.profiledata.segEarlyBirdPct.toFixed(1) + "%",
      pm: (this.profiledata.segEarlyBirdPct - this.benchmarks.segEarlyBirdBm).toFixed(1) + "%",
      ppl: this.profiledata.segEarlyBirdPpl
    },{      
      val: 'Lunch Bunch',
      hh: this.profiledata.segLunchBunchHh,
      pct: this.profiledata.segLunchBunchPct.toFixed(1) + "%",
      pm: (this.profiledata.segLunchBunchPct - this.benchmarks.segLunchBunchBm).toFixed(1) + "%",
      ppl: this.profiledata.segLunchBunchPpl
    },{      
      val: 'Gardening',
      hh: this.profiledata.segGardeningHh,
      pct: this.profiledata.segGardeningPct.toFixed(1) + "%",
      pm: (this.profiledata.segGardeningPct - this.benchmarks.segGardeningBm).toFixed(1) + "%",
      ppl: this.profiledata.segGardeningPpl
    }];
  }
}
</script>
