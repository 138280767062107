<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
export default {

  name: 'GenderTable',

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Household Gender' },
        { key: 'pct', label: 'Percentage of Households' },
        { key: 'hh', label: 'Households' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    this.items = [
      {
        val: 'Adult Male Only',
        pct: this.profiledata.genderMalePct.toFixed(1) + "%",
        hh: this.profiledata.genderMaleHh
      },{
        val: 'Adult Female Only',
        pct: this.profiledata.genderFemalePct.toFixed(1) + "%",
        hh: this.profiledata.genderFemaleHh
      },{
        val: 'Both',
        pct: this.profiledata.genderBothPct.toFixed(1) + "%",
        hh: this.profiledata.genderBothHh
      }
    ];
  }
}
</script>
