<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Year Range' },
        { key: 'hh', label: 'Households' },
        { key: 'pct', label: 'Percentage' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    this.items = [
      {
        val: '0-5 Years',
        hh: this.profiledata.yearsResident0to5Hh,
        pct: this.profiledata.yearsResident0to5Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident0to5Ppl
      },{
        val: '6-10 Years',
        hh: this.profiledata.yearsResident6to10Hh,
        pct: this.profiledata.yearsResident6to10Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident6to10Ppl
      },{
        val: '11-15 Years',
        hh: this.profiledata.yearsResident11to15Hh,
        pct: this.profiledata.yearsResident11to15Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident11to15Ppl
      },{
        val: '40-49 Years',
        hh: this.profiledata.yearsResident16to20Hh,
        pct: this.profiledata.yearsResident16to20Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident16to20Ppl
      },{
        val: '50-59 Years',
        hh: this.profiledata.yearsResident21to25Hh,
        pct: this.profiledata.yearsResident21to25Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident21to25Ppl
      },{
        val: '60-69 Years',
        hh: this.profiledata.yearsResident26to30Hh,
        pct: this.profiledata.yearsResident26to30Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResident26to30Ppl
      },{
        val: '70-79 Years',
        hh: this.profiledata.yearsResidentGt30Hh,
        pct: this.profiledata.yearsResidentGt30Pct.toFixed(1) + "%",
        ppl: this.profiledata.yearsResidentGt30Ppl
      }
    ];
  }
}
</script>
