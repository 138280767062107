<script>
import { HorizontalBar } from 'vue-chartjs';

export default {
  
  name: "EthnicityChart",
  
  extends: HorizontalBar,
  
  data() {
    return {

      chartdata: {
        labels: ['White', 'Black', 'Hispanic', 'Asian'],
        datasets: [{
          label: 'Ethnicity',
          data: [],
          backgroundColor: '#dd947c',
          borderColor: '#84584a',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            position: 'top',
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return `${value}%`;
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of Households'
            }
          }]
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.ethnicWhitePct);
    this.chartdata.datasets[0].data.push(this.profiledata.ethnicBlackPct);
    this.chartdata.datasets[0].data.push(this.profiledata.ethnicHispanicPct);
    this.chartdata.datasets[0].data.push(this.profiledata.ethnicAsianPct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
