<script>
import { HorizontalBar } from 'vue-chartjs';

export default {
  
  name: "YearsResidentChart",
  
  extends: HorizontalBar,
  
  data() {
    return {

      chartdata: {
        labels: ['0-5', '6-10', '11-15', '16-20', '21-25', '26-30', '>30'],
        datasets: [{
          label: 'Years Resident',
          data: [],
          backgroundColor: '#7ca586',
          borderColor: '#253128',
          borderWidth: 1
        }]
      },      
      
      chartoptions: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            position: 'top',
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return `${value}%`;
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'Percentage of Households'
            }
          }]
        }
      }
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.chartdata.datasets[0].data = [];
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident0to5Pct);
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident6to10Pct)
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident11to15Pct);
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident16to20Pct);
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident21to25Pct);
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResident26to30Pct);
    this.chartdata.datasets[0].data.push(this.profiledata.yearsResidentGt30Pct);

    this.renderChart(this.chartdata, this.chartoptions);
  }
}
</script>
