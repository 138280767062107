<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
export default {

  name: 'PoliticalPartyTable',

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Political Party' },
        { key: 'pct', label: 'Percentage' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    this.items = [
      {
        val: 'Democrat',
        pct: this.profiledata.regPartyCdDemocratPct.toFixed(1) + "%",
        ppl: this.profiledata.regPartyCdDemocratPpl
      },{
        val: 'Republican',
        pct: this.profiledata.regPartyCdRepublicanPct.toFixed(1) + "%",
        ppl: this.profiledata.regPartyCdRepublicanPpl
      },{
        val: 'Unaffilated',
        pct: this.profiledata.regPartyCdUnaffiliatedPct.toFixed(1) + "%",
        ppl: this.profiledata.regPartyCdUnaffiliatedPpl
      },
      // {
      //   val: 'Libertarian',
      //   pct: this.profiledata.regPartyCdLibertarianPct.toFixed(1) + "%",
      //   ppl: this.profiledata.regPartyCdLibertarianPpl
      // },{
      //   val: 'Green',
      //   pct: this.profiledata.regPartyCdGreenPct.toFixed(1) + "%",
      //   ppl: this.profiledata.regPartyCdGreenPpl
      // },{
      //   val: 'Unidentified',
      //   pct: this.profiledata.regPartyCdUnidentifiedPct.toFixed(1) + "%",
      //   ppl: this.profiledata.regPartyCdUnidentifiedPpl
      // },{
      //   val: 'Other',
      //   pct: this.profiledata.regPartyCdOtherPct.toFixed(1) + "%",
      //   ppl: this.profiledata.regPartyCdOtherPpl
      // }
    ];
  }
}
</script>
