<template>
  <div>
    <b-table-lite 
      striped 
      outlined 
      hover 
      :fields="fields" 
      :items="items" 
      :tbody-tr-class="rowClass"
      thead-class="table_header" />
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {

  name: "HomeValueTable",

  data() {
    return {
      items: [],
      fields: [
        { key: 'val', label: 'Home Value' },
        { key: 'hh', label: 'HHs'},
        { key: 'pct', label: '% of HHs' },
        { key: 'pm', label: '+/- Avg' },
        { key: 'ppl', label: 'People' }
      ]
    }
  },

  props: {
    profiledata: {
      type: Object,
      default: null
    },
    benchmarks: {
      type: Object,
      default: null
    }
  },

  methods: {
    rowClass() {
      return 'table-sm'
    }
  },

  mounted() {
    //console.info("HomeValueTable this.profiledata=%o", this.profiledata);

    this.items = [
    {
      val: '$1K-$25K',
      hh: this.profiledata.homeValue1to25kHh,
      pct: this.profiledata.homeValue1to25kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue1to25kPct - this.benchmarks.homeValue1to25kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue1to25kPpl
    },{
      val: '$25K-$50K',
      hh: this.profiledata.homeValue25to50kHh,
      pct: this.profiledata.homeValue25to50kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue25to50kPct - this.benchmarks.homeValue25to50kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue25to50kPpl
    },{
      val: '$50K-$75K',
      hh: this.profiledata.homeValue50to75kHh,
      pct: this.profiledata.homeValue50to75kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue50to75kPct - this.benchmarks.homeValue50to75kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue50to75kPpl
    },{
      val: '$75K-$100K',
      hh: this.profiledata.homeValue75to100kHh,
      pct: this.profiledata.homeValue75to100kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue75to100kPct - this.benchmarks.homeValue75to100kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue75to100kPpl
    },{
      val: '$100K-$125K',
      hh: this.profiledata.homeValue100to125kHh,
      pct: this.profiledata.homeValue100to125kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue100to125kPct - this.benchmarks.homeValue100to125kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue100to125kPpl
    },{
      val: '$125K-$150K',
      hh: this.profiledata.homeValue125to150kHh,
      pct: this.profiledata.homeValue125to150kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue125to150kPct - this.benchmarks.homeValue125to150kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue125to150kPpl
    },{
      val: '$150K-$175K',
      hh: this.profiledata.homeValue150to175kHh,
      pct: this.profiledata.homeValue150to175kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue150to175kPct - this.benchmarks.homeValue150to175kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue150to175kPpl
    },{
      val: '$175K-$200K',
      hh: this.profiledata.homeValue175to200kHh,
      pct: this.profiledata.homeValue175to200kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue175to200kPct - this.benchmarks.homeValue175to200kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue175to200kPpl
    },{
      val: '$200K-$225K',
      hh: this.profiledata.homeValue200to225kHh,
      pct: this.profiledata.homeValue200to225kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue200to225kPct - this.benchmarks.homeValue200to225kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue200to225kPpl
    },{
      val: '$225K-250K$',
      hh: this.profiledata.homeValue225to250kHh,
      pct: this.profiledata.homeValue225to250kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue225to250kPct - this.benchmarks.homeValue225to250kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue225to250kPpl
    },{
      val: '$250K-$275K',
      hh: this.profiledata.homeValue250to275kHh,
      pct: this.profiledata.homeValue250to275kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue250to275kPct - this.benchmarks.homeValue250to275kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue250to275kPpl
    },{
      val: '$275K-$300K',
      hh: this.profiledata.homeValue275to300kHh,
      pct: this.profiledata.homeValue275to300kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue275to300kPct - this.benchmarks.homeValue275to300kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue275to300kPpl
    },{
      val: '$300K-350K$',
      hh: this.profiledata.homeValue300to350kHh,
      pct: this.profiledata.homeValue300to350kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue300to350kPct - this.benchmarks.homeValue300to350kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue300to350kPpl
    },{
      val: '$350K-$400K',
      hh: this.profiledata.homeValue350to400kHh,
      pct: this.profiledata.homeValue350to400kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue350to400kPct - this.benchmarks.homeValue350to400kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue350to400kPpl
    },{
      val: '$400K-$450K',
      hh: this.profiledata.homeValue400to450kHh,
      pct: this.profiledata.homeValue400to450kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue400to450kPct - this.benchmarks.homeValue400to450kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue400to450kPpl
    },{
      val: '$450K-$500K',
      hh: this.profiledata.homeValue450to500kHh,
      pct: this.profiledata.homeValue450to500kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue450to500kPct - this.benchmarks.homeValue450to500kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue450to500kPpl
    },{
      val: '$500K-$775K',
      hh: this.profiledata.homeValue500to775kHh,
      pct: this.profiledata.homeValue500to775kPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue500to775kPct - this.benchmarks.homeValue500to775kBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue500to775kPpl
    },{
      val: '$775K-$1M',
      hh: this.profiledata.homeValue775kto1mHh,
      pct: this.profiledata.homeValue775kto1mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue775kto1mPct - this.benchmarks.homeValue775kto1mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue775kto1mPpl
    },{
      val: '$1M-$2M',
      hh: this.profiledata.homeValue1to2mHh,
      pct: this.profiledata.homeValue1to2mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue1to2mPct - this.benchmarks.homeValue1to2mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue1to2mPpl
    },{
      val: '$2M-3M$',
      hh: this.profiledata.homeValue2to3mHh,
      pct: this.profiledata.homeValue2to3mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue2to3mPct - this.benchmarks.homeValue2to3mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue2to3mPpl
    },{
      val: '$3M-5M$',
      hh: this.profiledata.homeValue3to5mHh,
      pct: this.profiledata.homeValue3to5mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue3to5mPct - this.benchmarks.homeValue3to5mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue3to5mPpl
    },{
      val: '$5M-$10M',
      hh: this.profiledata.homeValue5to10mHh,
      pct: this.profiledata.homeValue5to10mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValue5to10mPct - this.benchmarks.homeValue5to10mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValue5to10mPpl
    },{
      val: '>$10M',
      hh: this.profiledata.homeValueGt10mHh,
      pct: this.profiledata.homeValueGt10mPct.toFixed(1) + "%",
      pm: (this.profiledata.homeValueGt10mPct - this.benchmarks.homeValueGt10mBm).toFixed(1) + "%",
      ppl: this.profiledata.homeValueGt10mPpl
    }];
  }
}
</script>
